@use 'vars' as vars;

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

html {
  height: 100dvh;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
}

.container {
  max-width: clamp(1200px, 1200px, 100%);

  & > form { max-width: 100%; }
}

.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-block {
  display: block;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

$text-alignments: left, center, right;

@each $value in $text-alignments {
  .text-#{$value} {
    text-align: $value;
  }
}

$alignments: start, center, end, stretch, space-between;

@each $value in $alignments {
  .align-#{$value}, .align-items-#{$value} {
    align-items: $value;
  }
  .justify-content-#{$value} {
    justify-content: $value;
    @if $value == start {
      justify-content: flex-start;
    }
    @if $value == end {
      justify-content: flex-end;
    }
  }
}

@for $i from 1 through 20 {
  .w-#{$i*5} {
    width: $i * 5%;
  }
  .h-#{$i*5} {
    height: $i * 5%;
  }
}


@for $value from 0 through 64 {
  .gap-#{$value} {
    gap: $value + px;
  }

  .p-#{$value} {
    padding: $value + px !important;
  }
  .pt-#{$value} {
    padding-top: $value + px;
  }
  .pr-#{$value} {
    padding-right: $value + px;
  }
  .pb-#{$value} {
    padding-bottom: $value + px;
  }
  .pl-#{$value} {
    padding-left: $value + px;
  }
  .px-#{$value} {
    padding-left: $value + px;
    padding-right: $value + px;
  }
  .py-#{$value} {
    padding-top: $value + px;
    padding-bottom: $value + px;
  }

  .m-#{$value} {
    margin: $value + px;
  }
  .mt-#{$value} {
    margin-top: $value + px;
  }
  .mr-#{$value} {
    margin-right: $value + px;
  }
  .mb-#{$value} {
    margin-bottom: $value + px;
  }
  .ml-#{$value} {
    margin-left: $value + px;
  }
  .mx-#{$value} {
    margin-left: $value + px;
    margin-right: $value + px;
  }
  .my-#{$value} {
    margin-top: $value + px;
    margin-bottom: $value + px;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}


@for $value from 8 to 64 {
  .font-size-#{$value} {
    font-size: $value + px !important;
  }
}

$fontWeights: 100, 200, 300, 400, 500, 600, 700, 800, 900, bold;

@each $value in $fontWeights {
  .font-weight-#{$value} {
    font-weight: $value;
  }
}

$borderRadiuses: 1, 2, 3, 4, 5, 6, 8, 12, 16;

@each $value in $borderRadiuses {
  .b-#{$value} {
    border: 1px solid;
  }
}

.uppercase {
  text-transform: uppercase;
}

.no-wrap {
  text-wrap: nowrap;
}

.pointer {
  cursor: pointer;
  user-select: none;
}
.no-pointer-events {
  pointer-events: none;
}

.mdc-card {
  border-radius: 10px !important;
  overflow: hidden;
  box-shadow: 1px 1px 7px 0px #00000040 !important;
}

.trtl-menu {
  border-radius: 10px !important;
  overflow: hidden;
  box-shadow: 1px 1px 7px 0px #00000040 !important;
  background-color: #fff;
}

.bg-primary {
  background-color: var(--primary-color);
}
.bg-white {
  background-color: vars.$color-white;
}
.bg-black {
  background-color: vars.$color-black;
}
.bg-light-gray {
  background-color: vars.$color-light-gray;
}

.color-primary {
  color: var(--primary-color);
}
.color-white {
  color: vars.$color-white;
}
.color-black {
  color: vars.$color-black;
}
.color-gray {
  color: vars.$color-gray;
}
.color-light-gray {
  color: vars.$color-light-gray;
}

.trtl-pill {
  width: fit-content;
  border-radius: 100px;
  border: 1px solid vars.$color-light-gray;

  &.active {
    background-color: vars.$color-light-primary;
    border: 1px solid vars.$color-light-primary;
  }
}

.pointers-none {
  pointer-events: none;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
}

h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
}

h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin: 0;
}

h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

a {
  color: #0095FF;
}

.p-absolute {
  position: absolute;
}
.p-relative {
  position: relative;
}
.p-fixed {
  position: fixed;
}
.p-sticky {
  position: sticky;
}

.color-warn {
  color: vars.$color-red;
}

html[dir=rtl] .mat-icon {
  direction: rtl;
}

mat-snack-bar-container {
  margin-top: 70px !important;
}
